import React, { useMemo, useEffect, useState } from 'react'
import { graphql } from 'gatsby'
import queryString from 'query-string'
import InfiniteScroll from 'react-infinite-scroll-component'
import ClipLoader from 'react-spinners/ClipLoader'

import { useSearch } from 'hooks'
import SEO from 'components/SEO'
import ModelCard from 'components/ModelCard'

import styles from './ModelSearch.module.scss'

export const query = graphql`
  query ModelSearchPageQuery {

    banner: file(
      relativeDirectory: {regex: "/seo-banner/"},
      name: {eq: "home"}
    ) {
      publicURL
    }

    site {
      siteMetadata {
        siteUrl
      }
    }
  
  }
`

const calcPerPage = (windowWidth) => {
  if (!windowWidth) return 25 // case SSR window does not exist
  if (windowWidth <= 880) return 6
  if (windowWidth <= 1170 ) return 9
  if (windowWidth <= 1470 ) return 12
  return 15
}
/*
const options = {
  fields: {
    aka: { boost: 1 },
  },
  expand: true 
}
*/

const indexName = 'model'
const indexFilePath = '/csr-search-index/model-index.json'

const indexOptions = {
  idField: 'id',
  fields: ['aka'], // fields to index for full-text search
}

const queryOptions = {
  fields: ['aka'], // fields to search into
  boost: { aka: 1 },
  prefix: true,
  fuzzy: 0.2,
}

const ModelSearchPage = (props) => {
  const { site: { siteMetadata }, banner } = props.data
  const { query } = queryString.parse(props.location.search) || {}
  const windowWidth = typeof window !== 'undefined' && window.innerWidth
  const perPage = useMemo(() => calcPerPage(windowWidth), [windowWidth])
  const [results, isLoading] = useSearch(indexName, indexFilePath, indexOptions, query, queryOptions)
  const [showingResults, setShowingResults] = useState([])

  const getMoreResults = () => {
    const nextPageResults = results.slice(showingResults.length, showingResults.length + perPage)
    const newShowingResults = showingResults.concat(nextPageResults)
    setShowingResults(newShowingResults)
  }

  useEffect(() => setShowingResults(results.slice(0, perPage)), [results])

  const breadcrumb = useMemo(() => {
    
    const schema = {
      '@context': 'http://schema.org',
      '@type': 'BreadcrumbList',
      'itemListElement': [
        {
          "@type": "ListItem",
          "position": 1,
          "item": {
            "@id": `${siteMetadata.siteUrl}/models/`,
            "name": "Models"
          }
        },
        {
          "@type": "ListItem",
          "position": 2,
          "item": {
            "@id": `${siteMetadata.siteUrl}/models/search/`,
            "name": "Search"
          }
        },
      ]
    }

    if (query) {
      schema.itemListElement.push({
        "@type": "ListItem",
        "position": 3,
        "item": {
          "@id": `${siteMetadata.siteUrl}/models/search/?query=${query}`,
          "name": `${query}`
        }
      })
    }

    return schema;

  }, [siteMetadata, query])

  const Results = useMemo(() => (
    <>
      <InfiniteScroll
        dataLength={showingResults.length}
        next={getMoreResults}
        hasMore={showingResults.length < results.length}
        loader={<h4>Loading...</h4>}
      >
        <div className={`${styles.modelsBlock} model-cards-container`}>
          {showingResults.map((model, idx) => <ModelCard key={idx} data={model} />)}
        </div>
      </InfiniteScroll>
    </>
  ), [showingResults.length, showingResults[0], showingResults[showingResults.length-1]])

  return (
    <>
    <SEO
      breadcrumb={breadcrumb}
      title={`${query} camgirl ready to have a sex webcam show with you | SloppyDeep`}
      description={`Enjoy our amazing ${query} cam. We have got a huge directory of sexy cam models waiting to be uncovered. Find your favorite camgirl and enjoy the best live sex of your life`}
      keywords={`${query} cam, camgirl directory, ${query} cam recording, cam girl finder`}
      imagePath={banner.publicURL}
      imageAlt={'redhead teen cam girl wearing pink lingerie and posing sexy'}
      pagePath={query ? `/models/search/?query=${query}` : '/models/search/'}
      robots='index, follow'
    />
    <main className={`${styles.modelSearchScreen} screen-container`}>
      <h1 className={styles.titleBlock}>Results for: <span className={styles.query}>{query}</span></h1>
      { isLoading &&
        <div className={styles.loader}>
          <ClipLoader
            size={'3rem'}
            color={'#FF8A8A'}
          />
        </div>
      }
      {Results}
    </main>
    </>
  )

}

export default ModelSearchPage