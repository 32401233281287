import { useState, useContext, useEffect } from 'react'
import MiniSearch from 'minisearch'
import { SearchIndexContext } from 'utils/store'

const useSearch = (indexName, indexFilePath, indexOptions, query, queryOptions) => {
  const [indexes, setIndexes] = useContext(SearchIndexContext)
  const [results, setResults] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    console.log('Loading index')
    setIsLoading(true)

    fetch(indexFilePath)
      .then(res => res.json())
      .then(data => {
        const jsonIndex = JSON.stringify(data)
        
        setIndexes({...indexes, [indexName]: MiniSearch.loadJSON(jsonIndex, indexOptions)})
        setIsLoading(false)
      })
      .catch(err => {
        console.log(err)
        setIsLoading(false)
      })

  }, [indexName, indexFilePath, indexOptions])

  useEffect(() => {
    if (indexes[indexName] === null) return;

    console.log('searching...')

    const newResults = indexes[indexName].search(query, queryOptions)
    setResults(newResults)

  }, [indexes, setIndexes, query, queryOptions])
  
  return [results, isLoading]
}
export default useSearch